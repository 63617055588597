import { store } from '@/stores';
import { themeInterface } from './types';
import { defineStore } from 'pinia';

export const themeConfig = defineStore({
	id: 'themeConfig',
	state: (): themeInterface => ({
		// 是否
		isAreanDrawer: false,
		arenaInfo: {},
		uiConfig: {
		},
		isGetUi: false,
		isScannerinputFocus: true,
		isDark: false,
		notifies: 0,
		refreshTime: 0,
	}),
	getters: {},
	actions: {
	},
});

export function themeConfigHook() {
	return themeConfig(store);
}
