import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import indexRouter from './routes';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import errorRouter from './error';
// import { NextLoading } from '@/utils/loading';
// import { themeConfigHook } from '@/stores/modules/themeConfig';
import { routerListHook } from '@/stores/modules/routerList';
import { userInfoHook } from '@/stores/modules/userInfo';
import { themeConfigHook } from '@/stores/modules/themeConfig';
import service from '@/api/verifyToken';
import { Local } from '@/utils/storage';
import uiService from '@/api/api_uiconfig'
import { Utils } from '@/utils/utils';
// import { WSService } from '@/utils/ws/ws';
import useConVisit from '@/hooks/useConVisit';
import { getCurrentTimeAfter } from '@/utils/formatTime'

const whiteRoutes: RouteRecordRaw[] = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login/index.vue'),
		meta: {
			title: '登录页',
		},
	},
];

/**
 * 路由多级嵌套数组处理成一维数组
 * @param arr 传入路由菜单数据数组
 * @returns 返回处理后的一维路由菜单数组
 */
function formatFlatteningRoutes(arr: any) {
	if (arr.length <= 0) return false;
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].children) {
			arr = arr.slice(0, i + 1).concat(arr[i].children, arr.slice(i + 1));
		}
	}
	return arr;
}

/**
 * 一维数组处理成多级嵌套数组（只保留二级：也就是二级以上全部处理成只有二级，keep-alive 支持二级缓存）
 * @description isKeepAlive 处理 `name` 值，进行缓存。顶级关闭，全部不缓存
 * @link 参考：https://v3.cn.vuejs.org/api/built-in-components.html#keep-alive
 * @param arr 处理后的一维路由菜单数组
 * @returns 返回将一维数组重新处理成 `定义动态路由（dynamicRoutes）` 的格式
 */
export function formatTwoStageRoutes(arr: any) {
	if (arr.length <= 0) return false;
	const newArr: any = [];
	arr.forEach((v: any) => {
		if (v.path === '/') {
			newArr.push({ component: v.component, name: v.name, path: v.path, redirect: v.redirect, meta: v.meta, children: [] });
		} else {
			newArr[0].children.push({ ...v });
		}
	});
	return newArr;
}

const routes: RouteRecordRaw[] = [...whiteRoutes, ...indexRouter, ...errorRouter];

const userInfo = userInfoHook();

const router = createRouter({
	history: createWebHashHistory(),
	routes: routes,
	strict: true,
	scrollBehavior(to, from, savedPosition) {
		return new Promise((resolve) => {
			if (savedPosition) {
				return savedPosition;
			} else {
				if (from.meta.saveSrollTop) {
					const top: number = document.documentElement.scrollTop || document.body.scrollTop;
					resolve({ left: 0, top });
				}
			}
		});
	},
});

export async function initControlRoutes(data: any) {
	// 保持用户信息 商户logo
	userInfoHook().setUserInfo(data)

	// 保存系统通知的未读数量
	themeConfigHook().notifies = data.notifies

	// 进行权限控制
	const routes = setRoutes()
	// 动态保存路由
	await setAddRoute(routes)
}

/**
 * @description: 设置路由，超级管理员直接返回。其他权限进行路由过滤
 * @return 根据权限过滤后的路由表
 */
function setRoutes() {
	// const { isSuper } = userInfoHook()

	// // 超级管理员不需要进行权限控制
	// if (isSuper) return indexRouter

	const routes = indexRouter

	routerListHook().allOneRoutes = formatFlatteningRoutes(indexRouter[0].children)

	routes[0].children = filterRoute(indexRouter[0].children)

	return routes
}

/**
 * @description: 进行递归过滤，根据数据的alias和路由表meta中的source进行比对
 * @param {RouteRecordRaw} routes 嵌套过滤后的路由表
 * @return 过滤后的路由
 */
function filterRoute(routes: RouteRecordRaw[]): RouteRecordRaw[] {
	const { items } = userInfoHook()
	const routesList: RouteRecordRaw[] = []
	routes.forEach((route: RouteRecordRaw) => {
		if (route.meta.source === 'index') {
			routesList.push(route)
		} else if (route.meta.requireAuth === false) { // 不需要权限判断的 不需要进行权限控制
			routesList.push(route)
		} else if (route.children && route.children.length > 0) {
			const subRoutes = filterRoute(route.children)

			if (subRoutes.length > 0) {
				route.children = subRoutes
				route.redirect = subRoutes[0].path
				routesList.push(route)
			}
		} else {
			const index = items?.findIndex((item: any) => item.alias === route.meta.source)

			if (index >= 0) routesList.push(route)
		}
	})
	return routesList
}

/**
 * @description: 动态设置项目的路由
 * @param {any} routeList
 * @return {*}
 */
async function setAddRoute(routeList: any) {

	const routes = formatFlatteningRoutes(routeList);
	routerListHook().routes = indexRouter[0].children;
	routerListHook().oneRoutes = routes;

	// routeList.forEach(item => {
	// 	router.addRoute(item)
	// })
}

// 获取后台
async function setConfig() {
	// https://mp.arena7.cn  三级子域名
	// https://ace.m.arena7.cn  四级子域名
	let subDomain = ''; // 四级子域名
	const hosts = location.host.split('.')
	if (hosts.length == 4) {
		subDomain = import.meta.env.MODE === 'development' ? '' : hosts[0];
	}

	const res = await uiService.uiConfig({
		subDomain
	})
	// 当接口返回res.redirect时，跳转到 #/404
	if (res.redirect) {
		window.location.href = res.redirect;
		return
	}


	themeConfigHook().isGetUi = true

	themeConfigHook().uiConfig = res

	if (res.ui?.mainColor) {
		const { mainColor } = res.ui
		document.body.style.setProperty('--no7-theme-color', mainColor)
		document.body.style.setProperty('--no7-theme-color-1', Utils.getColorOpacity(mainColor, 0.1))
		document.body.style.setProperty('--no7-theme-color-2', Utils.getColorOpacity(mainColor, 0.2))
		document.body.style.setProperty('--no7-three-tab-bg', Utils.getColorOpacity(mainColor, 0.2))
		document.body.style.setProperty('--no7-theme-color-3', Utils.getColorOpacity(mainColor, 0.3))
		document.body.style.setProperty('--no7-theme-color-4', Utils.getColorOpacity(mainColor, 0.4))
		document.body.style.setProperty('--no7-theme-color-5', Utils.getColorOpacity(mainColor, 0.5))
	}

	if (res.logo) {
		changeFavicon(res.logo)
	}
}

const changeFavicon = link => {
	let $favicon: any = document.querySelector('link[rel="icon"]');
	if ($favicon !== null) {
		$favicon.href = link;
	} else {
		$favicon = document.createElement("link");
		$favicon.rel = "icon";
		$favicon.href = link;
		document.head.appendChild($favicon);
	}
}

const conShow = (to) => {
	const allOneRoutes = routerListHook().allOneRoutes

	const route = allOneRoutes.find(item => item.path === to.path)

	// 没有找到对应的路由，404
	if (!route || '/welcome' === to.path || route.meta.requireAuth === false) return true

	const source = route.meta.source as string

	return useConVisit('read', source) || useConVisit('show', source)
}

router.beforeEach(async (to, from, next) => {
	NProgress.configure({ showSpinner: false });
	if (to.meta.title) NProgress.start();
	const token = Local.get('token')
	if (!themeConfigHook().isGetUi && import.meta.env.MODE !== 'development') {
		// 开发版本不请求ui后台配置
		await setConfig()
	}

	if (token && to.path !== '/login') {
		// 如果没有登录或者刷新的时候 进行token判断是否过期
		if (userInfo.isVerify) {
			if (conShow(to)) {
				next();
			} else {
				next({
					path: '/error/403',
					replace: true
				})
			}
		} else {
			try {
				const res: any = await service.verifyUrl();
				// WSService.init();
				Local.set('token', res.user.token);
				await initControlRoutes(res)
				if (conShow(to)) {
					next({ path: to.path, query: to.query });
				} else {
					next({
						path: '/error/403',
						replace: true
					})
				}
			} catch (error) {
				next('/login');
			}
		}
	} else {
		if (to.path === '/login') {
			next()
		} else {
			next('/login')
		}
	}
});

// 路由加载后
router.afterEach(() => {
	const dateTime = new Date().getTime()
	userInfoHook().clickNum += 1
	if (userInfoHook().clickNum >= 25 || themeConfigHook().refreshTime <= dateTime) {
		setTimeout(() => {
			window.location.reload()
		})
		userInfoHook().clickNum = 0
		themeConfigHook().refreshTime = getCurrentTimeAfter(1)
	}
	NProgress.done();
});

export default router;