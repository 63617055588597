import { RouteRecordRaw } from 'vue-router';

/**
 * 路由meta对象参数说明
 * meta: {
 *      title:          菜单栏及 tagsView 栏
 *      isHide：        是否隐藏此路由
 *      isKeepAlive：   是否缓存组件状态
 *      isAffix：       是否固定在 tagsView 栏上
 *      icon：          菜单、tagsView 图标，阿里：加 `iconfont xxx`，fontawesome：加 `fa xxx`
 *      source:         权限控制的标识符，根据这个字段进行判断是否
 *      desc:           页面的形容语句
 *      upLevel:        在tagsView中，如果upLevel相同会进行覆盖, 并且通过upLevel来找到对应的二级路由
 * 		requireAuth:    改路由是否需要进行权限判断
 */

// 定义路由时，必须带上一级页面路由路径。否者调整二级或者三级页面会不知道对应的一级路由是哪个
const indexRouter: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: '/',
		component: () => import('@/layout/index.vue'),
		redirect: '/welcome',
		meta: {
			title: '首页',
			source: 'index',
		},
		children: [
			{
				path: '/welcome',
				name: 'welcome',
				component: () => import('@/views/welcome/index.vue'),
				meta: {
					title: '首页',
					isKeepAlive: true,
					isAffix: true,
					requireAuth: true,
					icon: 'iconfont iconshouye',
					source: 'index',
				},
			},
			{
				path: '/venue',
				name: 'venue',
				meta: {
					title: '门店',
					isKeepAlive: true,
					requireAuth: true,
					icon: 'iconfont iconchangguan2',
				},
				children: [
					{
						path: '/venue/cockpit',
						name: 'cockpit',
						meta: {
							title: '前台',
							desc: '门店运营操作总览中心，订场、销售等门店业务中心',
							requireAuth: true,
							isKeepAlive: true,
							icon: 'iconfont icongailan',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/venues/cockpit/operationCenter',
								name: 'operationCenter',
								meta: { title: '操作中心', requireAuth: true, isKeepAlive: false, source: 'opCenter', upLevel: 'cockpit' },
								component: () => import('@/views/venue/cockpit/components/operationCenter/operationCenter.vue'),
							},
							{
								path: '/venue/venues/cockpit/arenaBooking',
								name: 'arenaBooking',
								meta: { title: '订场管理', requireAuth: true, isKeepAlive: true, source: 'book', upLevel: 'cockpit' },
								component: () => import('@/views/venue/cockpit/components/arenaBooking/arenaBooking.vue'),
							},
							{
								path: '/venue/venues/cockpit/vendingMachine',
								name: 'vendingMachine',
								meta: { title: '售货机', requireAuth: true, isKeepAlive: true, source: 'resaleVendingMachine', upLevel: 'cockpit' },
								component: () => import('@/views/venue/cockpit/components/vendingMachine/vendingMachine.vue'),
							},
						],
					},
					{
						path: '/venue/finance',
						name: 'arena_finance',
						meta: {
							title: '财务',
							requireAuth: true,
							isKeepAlive: true,
							desc: '店内订单、收款、收入等财务管理',
							icon: 'iconfont iconcaiwu4',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/settlement/finance/order',
								name: 'arena_finance_order',
								meta: {
									title: '订单',
									requireAuth: true,
									isKeepAlive: true,
									source: 'orders',
									upLevel: 'arena_finance',
								},
								component: () => import('@/views/finance/order/layout-order.vue'),
								children: [
									{
										path: '/venue/finance/order/all',
										name: 'arena_finance_all_order',
										meta: {
											title: '全部订单',
											requireAuth: true,
											isKeepAlive: true,
											source: 'orders',
											upLevel: 'arena_finance',
											father: 'arena_finance_order',
										},
										component: () => import('@/views/finance/order/all/index.vue'),
									},
									// {
									// 	path: '/finance/order/time',
									// 	name: 'arena_finance_time_order',
									// 	meta: { title: '计时订单', requireAuth: true, isKeepAlive: true, source: 'financeOrder', upLevel: 'arena_finance',father: 'arena_finance_order', },
									// 	component: () => import('@/views/finance/order/time/index.vue'),
									// },
									{
										path: '/venue/finance/order/long',
										name: 'arena_finance_long_order',
										meta: {
											title: '长包订单',
											requireAuth: true,
											isKeepAlive: true,
											source: 'longTermOrders',
											upLevel: 'arena_finance',
											father: 'arena_finance_order',
										},
										component: () => import('@/views/finance/order/long/index.vue'),
									},
									{
										path: '/venue/finance/order/about',
										name: 'arena_finance_about_order',
										meta: {
											title: '约球订单',
											requireAuth: true,
											isKeepAlive: true,
											source: 'appointments',
											upLevel: 'arena_finance',
											father: 'arena_finance_order',
										},
										component: () => import('@/views/finance/order/about/index.vue'),
									},
								],
							},
							{
								path: '/venue/finance/closeAnAccount',
								name: 'arena_finance_close_an_account',
								meta: {
									title: '财务结算',
									requireAuth: true,
									isKeepAlive: true,
									source: 'settlements',
									upLevel: 'arena_finance',
								},
								component: () => import('@/views/finance/closeAnAccount/index.vue'),
							},
							{
								path: '/venue/finance/invoice',
								name: 'arena_finance_invoice',
								meta: {
									title: '发票',
									requireAuth: true,
									isKeepAlive: true,
									source: 'invoices',
									upLevel: 'arena_finance',
								},
								component: () => import('@/views/finance/invoice/index.vue'),
							},
						],
					},
					{
						path: '/venue/cardTicket',
						name: 'arena_card_ticket',
						meta: {
							title: '卡票',
							requireAuth: true,
							isKeepAlive: true,
							icon: 'iconfont iconkabao',
							desc: '管理店内销售的卡票等商品',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/venues/cardTicket/individualTickets',
								name: 'arena_individual_ticket',
								meta: {
									title: '单次票',
									requireAuth: true,
									isKeepAlive: true,
									source: 'individualTickets',
									upLevel: 'arena_card_ticket',
								},
								component: () => import('@/views/card/individualTicket/components/individualTicketPage/index.vue'),
							},
							{
								path: '/venue/venues/cardTicket/depositCards',
								name: 'arena_deposit_card',
								meta: {
									title: '充值卡',
									requireAuth: true,
									isKeepAlive: true,
									source: 'depositCards',
									upLevel: 'arena_card_ticket',
								},
								component: () => import('@/views/card/depositCard/components/depositCardPage/index.vue'),
							},
							{
								path: '/venue/venues/cardTicket/memberCards',
								name: 'arena_member_card',
								meta: {
									title: '月卡',
									requireAuth: true,
									isKeepAlive: true,
									source: 'cards',
									upLevel: 'arena_card_ticket',
								},
								component: () => import('@/views/card/memberCard/components/memberCardPage/index.vue'),
							},
							{
								path: '/venue/venues/cardTicket/counterCards',
								name: 'arena_counter_card',
								meta: {
									title: '次卡',
									requireAuth: true,
									isKeepAlive: true,
									source: 'counterCards',
									upLevel: 'arena_card_ticket',
								},
								component: () => import('@/views/card/counterCard/components/counterCardPage/index.vue'),
							},
							{
								path: '/venue/venues/cardTicket/balanceCards',
								name: 'arena_balance_card',
								meta: {
									title: '余额卡',
									requireAuth: true,
									isKeepAlive: true,
									source: 'balanceCards',
									upLevel: 'arena_card_ticket',
								},
								component: () => import('@/views/card/balanceCard/components/balanceCardPage/index.vue'),
							},
							{
								path: '/venue/venues/cardTicket/svipCards',
								name: 'arena_svip_card',
								meta: {
									title: 'VIP卡',
									requireAuth: true,
									isKeepAlive: true,
									source: 'SVIPCards',
									upLevel: 'arena_card_ticket',
								},
								component: () => import('@/views/card/svipCard/components/svipCardPage/index.vue'),
							},
							{
								path: '/venue/venues/cardTicket/loveCards',
								name: 'arena_love_card',
								meta: {
									title: '爱心卡',
									requireAuth: true,
									isKeepAlive: true,
									source: 'loveCards ???',
									upLevel: 'arena_card_ticket',
								},
								component: () => import('@/views/card/loveCard/components/loveCardPage/index.vue'),
							},
							{
								path: '/venue/venues/cardTicket/giftCards',
								name: 'arena_gift_card',
								meta: {
									title: '发放记录',
									requireAuth: true,
									isKeepAlive: true,
									source: 'giftCards ???',
									upLevel: 'arena_card_ticket',
								},
								component: () => import('@/views/card/giftCard/components/giftCardPage/index.vue'),
							},
						],
					},
					{
						path: '/venue/users',
						name: 'venue_users_index',
						meta: {
							title: '用户',
							requireAuth: true,
							isKeepAlive: true,
							desc: '店内用户管理',
							icon: 'iconfont iconyonghu4',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/venues/users/usersIndex/allUser',
								name: 'venue_all_user',
								meta: {
									title: '店内用户',
									requireAuth: true,
									isKeepAlive: true,
									source: 'users',
									upLevel: 'venue_users_index',
								},
								component: () => import('@/views/user/users/layout-user.vue'),
								children: [
									{
										path: '/venue/venues/users/allUser/allUserList',
										name: 'venue_all_user_list',
										meta: {
											title: '店内用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 0,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
									{
										path: '/venue/venues/users/allUser/fullUserList',
										name: 'venue_full_user_list',
										meta: {
											title: '整租用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 10,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
									{
										path: '/venue/venues/users/allUser/longUserList',
										name: 'venue_long_user_list',
										meta: {
											title: '长包用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 11,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
									{
										path: '/venue/venues/users/allUser/looseUserList',
										name: 'venue_loose_user_list',
										meta: {
											title: '散客用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 12,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
									{
										path: '/venue/venues/users/allUser/memberUserList',
										name: 'venue_member_user_list',
										meta: {
											title: '月卡用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 21,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
									{
										path: '/venue/venues/users/allUser/depositUserList',
										name: 'venue_deposit_user_list',
										meta: {
											title: '充值卡用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 22,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
									{
										path: '/venue/venues/users/allUser/balanceUserList',
										name: 'venue_balance_user_list',
										meta: {
											title: '余额卡用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 23,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
									{
										path: '/venue/venues/users/allUser/counterUserList',
										name: 'venue_counter_user_list',
										meta: {
											title: '次卡用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 24,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
									{
										path: '/venue/venues/users/allUser/vipUserList',
										name: 'venue_vip_user_list',
										meta: {
											title: 'VIP卡用户',
											requireAuth: true,
											isKeepAlive: true,
											source: 'users',
											upLevel: 'venue_users_index',
											father: 'venue_all_user',
											aSubType: 25,
										},
										component: () => import('@/views/user/users/allUsers/index.vue'),
									},
								],
							},
							{
								path: '/venue/venues/users/LongUser',
								name: 'venueLongUser',
								meta: { title: '长包用户', requireAuth: true, isKeepAlive: true, source: 'longTermUsers', upLevel: 'venue_users_index' },
								component: () => import('@/views/user/longUser/index.vue'),
							},
						],
					},
					{
						path: '/venue/goods',
						name: 'arena_goods',
						meta: {
							title: '商品',
							requireAuth: true,
							isKeepAlive: true,
							source: 'resaleOfflineInArena',
							desc: '店内用户管理',
							icon: 'iconfont iconshangpin',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/venues/goods/goods',
								name: 'goods',
								meta: {
									title: '商品',
									requireAuth: true,
									isKeepAlive: true,
									source: 'resaleOfflineInArena',
									upLevel: 'arena_goods',
								},
								component: () => import('@/views/venue/goods/components/goods/index.vue'),
							},
							{
								path: '/venue/venues/goods/inventory',
								name: 'inventory',
								meta: {
									title: '库存',
									requireAuth: true,
									isKeepAlive: true,
									source: 'resaleOfflineInArena',
									upLevel: 'arena_goods',
								},
								component: () => import('@/views/venue/goods/components/inventory/index.vue'),
							},
							{
								path: '/venue/venues/goods/goodsOrder',
								name: 'goodsOrder',
								meta: {
									title: '销售记录',
									requireAuth: true,
									isKeepAlive: true,
									source: 'resaleOfflineInArena',
									upLevel: 'arena_goods',
								},
								component: () => import('@/views/venue/goods/components/goodsOrder/index.vue'),
							},
						],
					},
					{
						path: '/venue/court',
						name: 'arena_court',
						meta: { title: '场地', requireAuth: true, isKeepAlive: true, icon: 'iconfont iconchangdi' },
						// component: () => import('@/views/venue/court/index.vue'),
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/venues/court/courtList',
								name: 'courtList',
								meta: { title: '场地', requireAuth: true, isKeepAlive: true, source: 'arenaCourt', upLevel: 'arena_court' },
								component: () => import('@/views/venue/court/components/courtList/index.vue'),
							},
							{
								path: '/venue/venues/court/configure',
								name: 'configure',
								meta: { title: '平面图', requireAuth: false, isKeepAlive: true, upLevel: 'arena_court' },
								component: () => import('@/views/venue/court/components/configure/index.vue'),
							},
						],
					},
					{
						path: '/venue/device',
						name: 'arena_device',
						meta: {
							title: '设备',
							requireAuth: true,
							source: 'intelligentDevices',
							isKeepAlive: true,
							icon: 'iconfont iconshebei',
							desc: '店内设备状态查看管理',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/device/deviceList',
								name: 'arenaDeviceList',
								meta: {
									title: '设备列表',
									requireAuth: true,
									isKeepAlive: true,
									source: 'intelligentDevices',
									upLevel: 'arena_device',
								},
								component: () => import('@/views/venue/device/arenaDeviceList/index.vue'),
							},
							{
								path: '/venue/device/sensor',
								name: 'arenaSensor',
								meta: {
									title: '传感器列表',
									requireAuth: true,
									isKeepAlive: true,
									source: 'intelligentDevices',
									upLevel: 'arena_device',
								},
								component: () => import('@/views/venue/device/arenaSensor/index.vue'),
							},
						],
					},
					{
						path: '/venue/statistics',
						name: 'arena_statistics',
						meta: {
							title: '统计',
							requireAuth: true,
							source: 'report',
							isKeepAlive: true,
							icon: 'iconfont icontongji',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/statistics/finance',
								name: 'arenaFinanceStatistics',
								meta: {
									title: '财务统计',
									requireAuth: true,
									isKeepAlive: true,
									source: 'report',
									upLevel: 'arena_statistics',
								},
								component: () => import('@/views/finance/statistics/index.vue'),
							},
							{
								path: '/venue/statistics/goods',
								name: 'arenaGoodsStatistics',
								meta: {
									title: '商品统计',
									requireAuth: true,
									isKeepAlive: true,
									source: 'report',
									upLevel: 'arena_statistics',
								},
								component: () => import('@/views/venue/goods/components/statistics/index.vue'),
							},
							{
								path: '/venue/statistics/user',
								name: 'arenaUserStatistics',
								meta: {
									title: '用户统计',
									requireAuth: true,
									isKeepAlive: true,
									source: 'report',
									upLevel: 'arena_statistics',
								},
								component: () => import('@/views/user/statistics/index.vue'),
							},
						],
					},
					{
						path: '/venue/configurations',
						name: 'arena_configurations',
						meta: {
							title: '设置',
							requireAuth: false,
							isKeepAlive: true,
							icon: 'iconfont iconshezhi2',
							desc: '门店营业规则设置',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/venue/venues/configurations/basicInformation',
								name: 'basic_information',
								meta: {
									title: '基本信息',
									requireAuth: false,
									isKeepAlive: true,
									upLevel: 'arena_configurations',
								},
								component: () => import('@/views/venue/configurations/components/basicInformation/index.vue'),
							},
							{
								path: '/venue/venues/configurations/tradingInformation',
								name: 'trading_information',
								meta: {
									title: '营业信息',
									requireAuth: false,
									isKeepAlive: true,
									upLevel: 'arena_configurations',
								},
								component: () => import('@/views/venue/configurations/components/tradingInformation/index.vue'),
							},
							{
								path: '/venue/venues/configurations/fullSiteSet',
								name: 'full_site_set',
								meta: {
									title: '整租设置',
									requireAuth: false,
									isKeepAlive: true,
									upLevel: 'arena_configurations',
								},
								component: () => import('@/views/venue/configurations/components/fullSiteSet/index.vue'),
							},
							{
								path: '/venue/venues/configurations/looseSiteSet',
								name: 'loose_site_set',
								meta: {
									title: '散场设置',
									requireAuth: false,
									isKeepAlive: true,
									upLevel: 'arena_configurations',
								},
								component: () => import('@/views/venue/configurations/components/looseSiteSet/index.vue'),
							},
							{
								path: '/venue/venues/configurations/miniProgramSet',
								name: 'mini_program_set',
								meta: {
									title: '小程序设置',
									requireAuth: false,
									isKeepAlive: true,
									upLevel: 'arena_configurations',
								},
								component: () => import('@/views/venue/configurations/components/miniProgramSet/index.vue'),
							},
						],
					},
				],
			},
			{
				path: '/coach',
				name: 'coach',
				meta: { title: '教练', requireAuth: true, icon: 'iconfont iconsijiao' },
				children: [
					{
						path: '/coach/bookingManagement',
						name: 'bookingManagement',
						meta: { title: '私课管理', isKeepAlive: true, source: 'coachAppoint' },
						component: () => import('@/views/coach/bookingManagement/index.vue'),
					},
					{
						path: '/coach/coachManagement',
						name: 'coachManagement',
						meta: { title: '教练管理', isKeepAlive: true, source: 'coach' },
						component: () => import('@/views/coach/coachManagement/index.vue'),
					},
				]
			},
			{
				path: '/card',
				name: 'card',
				meta: { title: '卡票', requireAuth: true, icon: 'iconfont iconkaquan2' },
				children: [
					{
						path: '/card/individualTickets',
						name: 'individual_ticket_index',
						meta: { title: '单次票', source: 'individualTickets', isKeepAlive: true, desc: '单次票的创建销售统计管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/card/individualTicket/individualTicketPage',
								name: 'individual_ticket_page',
								meta: { title: '单次票', requireAuth: true, isKeepAlive: true, source: 'individualTickets', upLevel: 'individual_ticket_index' },
								component: () => import('@/views/card/individualTicket/components/individualTicketPage/index.vue'),
							},
							{
								path: '/card/individualTicket/writeOff',
								name: 'write_off',
								meta: { title: '核销记录', requireAuth: true, isKeepAlive: true, source: 'individualTickets', upLevel: 'individual_ticket_index' },
								component: () => import('@/views/card/individualTicket/components/writeOff/index.vue'),
							},
							{
								path: '/card/individualTicket/individualTicketGift',
								name: 'individual_ticket_gift',
								meta: {
									title: '发放记录',
									requireAuth: true,
									isKeepAlive: true,
									source: 'individualTickets',
									upLevel: 'individual_ticket_index',
									params: { type: 4 },
								},
								component: () => import('@/views/card/giftCard/components/giftCardPage/index.vue'),
							},
							// {
							// 	path: '/card/individualTicket/salesStatistics',
							// 	name: 'ticket_sales_statistics',
							// 	meta: {
							// 		title: '销售统计',
							// 		requireAuth: true,
							// 		isKeepAlive: true,
							// 		source: 'individualTickets',
							// 		upLevel: 'individual_ticket_index',
							// 		cardType: 1,
							// 	},
							// 	component: () => import('@/views/card/statistics/index.vue'),
							// },
						],
					},
					{
						path: '/card/depositCards',
						name: 'deposit_card_index',
						meta: { title: '充值卡', source: 'depositCards', isKeepAlive: true, desc: '充值卡的创建销售统计管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/card/depositCards/depositCardPage',
								name: 'deposit_card_page',
								meta: { title: '充值卡', requireAuth: true, isKeepAlive: true, source: 'depositCards', upLevel: 'deposit_card_index' },
								component: () => import('@/views/card/depositCard/components/depositCardPage/index.vue'),
							},
							{
								path: '/card/depositCards/depositCardRecord',
								name: 'deposit_card_record',
								meta: { title: '销售记录', requireAuth: true, isKeepAlive: true, source: 'depositCards', upLevel: 'deposit_card_index' },
								component: () => import('@/views/card/depositCard/components/depositCardRecord/index.vue'),
							},
							{
								path: '/card/depositCards/depositCardGift',
								name: 'deposit_card_gift',
								meta: {
									title: '发放记录',
									requireAuth: true,
									isKeepAlive: true,
									source: 'depositCards',
									upLevel: 'deposit_card_index',
									params: { type: 3 },
								},
								component: () => import('@/views/card/giftCard/components/giftCardPage/index.vue'),
							},
							// {
							// 	path: '/card/depositCards/salesStatistics',
							// 	name: 'deposit_sales_statistics',
							// 	meta: {
							// 		title: '销售统计',
							// 		requireAuth: true,
							// 		isKeepAlive: true,
							// 		source: 'depositCards',
							// 		upLevel: 'deposit_card_index',
							// 		cardType: 2,
							// 	},
							// 	component: () => import('@/views/card/statistics/index.vue'),
							// },
						],
					},
					{
						path: '/card/memberCards',
						name: 'member_card_index',
						meta: { title: '月卡', source: 'cards', isKeepAlive: true, desc: '月卡的创建销售统计管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/card/cards/memberCardPage',
								name: 'member_card_page',
								meta: { title: '月卡', requireAuth: true, isKeepAlive: true, source: 'cards', upLevel: 'member_card_index' },
								component: () => import('@/views/card/memberCard/components/memberCardPage/index.vue'),
							},
							{
								path: '/card/cards/memberCardRecord',
								name: 'member_card_record',
								meta: { title: '销售记录', requireAuth: true, isKeepAlive: true, source: 'cards', upLevel: 'member_card_index' },
								component: () => import('@/views/card/memberCard/components/memberCardRecord/index.vue'),
							},
							{
								path: '/card/cards/cardsGift',
								name: 'card_gift',
								meta: { title: '发放记录', requireAuth: true, isKeepAlive: true, source: 'cards', upLevel: 'member_card_index', params: { type: 1 } },
								component: () => import('@/views/card/giftCard/components/giftCardPage/index.vue'),
							},
							{
								path: '/card/cards/memberCardPageGroup',
								name: 'member_card_page_group',
								meta: { title: '月卡组', requireAuth: true, isKeepAlive: true, source: 'cards', upLevel: 'member_card_index' },
								component: () => import('@/views/card/memberCard/components/memberCardPageGroup/index.vue'),
							},
							// {
							// 	path: '/card/cards/salesStatistics',
							// 	name: 'cards_sales_statistics',
							// 	meta: {
							// 		title: '销售统计',
							// 		requireAuth: true,
							// 		isKeepAlive: true,
							// 		source: 'cards',
							// 		upLevel: 'member_card_index',
							// 		cardType: 6,
							// 	},
							// 	component: () => import('@/views/card/statistics/index.vue'),
							// },
						],
					},
					{
						path: '/card/counterCards',
						name: 'counter_card_index',
						meta: { title: '次卡', source: 'counterCards', isKeepAlive: true, desc: '次卡的创建销售统计管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/card/counterCards/counterCardPage',
								name: 'counter_card_page',
								meta: { title: '次卡', requireAuth: true, isKeepAlive: true, source: 'counterCards', upLevel: 'counter_card_index' },
								component: () => import('@/views/card/counterCard/components/counterCardPage/index.vue'),
							},
							{
								path: '/card/counterCards/counterCardRecord',
								name: 'counter_card_record',
								meta: { title: '销售记录', requireAuth: true, isKeepAlive: true, source: 'counterCards', upLevel: 'counter_card_index' },
								component: () => import('@/views/card/counterCard/components/counterCardRecord/index.vue'),
							},
							// {
							// 	path: '/card/counterCards/salesStatistics',
							// 	name: 'counter_sales_statistics',
							// 	meta: {
							// 		title: '销售统计',
							// 		requireAuth: true,
							// 		isKeepAlive: true,
							// 		source: 'counterCards',
							// 		upLevel: 'counter_card_index',
							// 		cardType: 3,
							// 	},
							// 	component: () => import('@/views/card/statistics/index.vue'),
							// },
						],
					},
					{
						path: '/card/balanceCards',
						name: 'balance_card_index',
						meta: { title: '余额卡', source: 'balanceCards', isKeepAlive: true, desc: '余额卡的创建销售统计管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/card/balanceCards/balanceCardPage',
								name: 'balance_card_page',
								meta: { title: '余额卡', requireAuth: true, isKeepAlive: true, source: 'balanceCards', upLevel: 'balance_card_index' },
								component: () => import('@/views/card/balanceCard/components/balanceCardPage/index.vue'),
							},
							{
								path: '/card/balanceCards/balanceCardRecord',
								name: 'balance_card_record',
								meta: { title: '销售记录', requireAuth: true, isKeepAlive: true, source: 'balanceCards', upLevel: 'balance_card_index' },
								component: () => import('@/views/card/balanceCard/components/balanceCardRecord/index.vue'),
							},
							// {
							// 	path: '/card/balanceCards/salesStatistics',
							// 	name: 'balance_sales_statistics',
							// 	meta: {
							// 		title: '销售统计',
							// 		requireAuth: true,
							// 		isKeepAlive: true,
							// 		source: 'balanceCards',
							// 		upLevel: 'balance_card_index',
							// 		cardType: 5,
							// 	},
							// 	component: () => import('@/views/card/statistics/index.vue'),
							// },
						],
					},
					{
						path: '/card/svipCards',
						name: 'svip_card_index',
						meta: { title: 'VIP卡', source: 'SVIPCards', isKeepAlive: true, desc: 'VIP卡的创建销售统计管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/card/svipCards/svipCardPage',
								name: 'svip_card_page',
								meta: { title: 'VIP卡', requireAuth: true, isKeepAlive: true, source: 'SVIPCards', upLevel: 'svip_card_index' },
								component: () => import('@/views/card/svipCard/components/svipCardPage/index.vue'),
							},
							{
								path: '/card/svipCards/svipCardRecord',
								name: 'svip_card_record',
								meta: { title: '销售记录', requireAuth: true, isKeepAlive: true, source: 'SVIPCards', upLevel: 'svip_card_index' },
								component: () => import('@/views/card/svipCard/components/svipCardRecord/index.vue'),
							},
							{
								path: '/card/svipCards/svipCardsGift',
								name: 'svip_card_gift',
								meta: {
									title: '发放记录',
									requireAuth: true,
									isKeepAlive: true,
									source: 'SVIPCards',
									upLevel: 'svip_card_index',
									params: { type: 2 },
								},
								component: () => import('@/views/card/giftCard/components/giftCardPage/index.vue'),
							},
							// {
							// 	path: '/card/svipCards/salesStatistics',
							// 	name: 'svip_sales_statistics',
							// 	meta: {
							// 		title: '销售统计',
							// 		requireAuth: true,
							// 		isKeepAlive: true,
							// 		source: 'SVIPCards',
							// 		upLevel: 'svip_card_index',
							// 		cardType: 4,
							// 	},
							// 	component: () => import('@/views/card/statistics/index.vue'),
							// },
						],
					},
					{
						path: '/card/loveCards',
						name: 'love_card_index',
						meta: { title: '爱心卡', source: 'loveCards', isKeepAlive: true, desc: '爱心卡的创建销售统计管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/card/loveCards/loveCardPage',
								name: 'love_card_page',
								meta: { title: '爱心卡', requireAuth: true, isKeepAlive: true, source: 'loveCards', upLevel: 'love_card_index' },
								component: () => import('@/views/card/loveCard/components/loveCardPage/index.vue'),
							},
							// {
							// 	path: '/card/loveCards/salesStatistics',
							// 	name: 'love_sales_statistics',
							// 	meta: {
							// 		title: '销售统计',
							// 		requireAuth: true,
							// 		isKeepAlive: true,
							// 		source: 'loveCards',
							// 		upLevel: 'love_card_index',
							// 		cardType: 7,
							// 	},
							// 	component: () => import('@/views/card/statistics/index.vue'),
							// },
						],
					},
					// {
					// 	path: '/card/statistics',
					// 	name: 'card_statistics',
					// 	meta: { title: '报表', isKeepAlive: true, source: 'report', },
					// 	component: () => import('@/views/card/statistics/index.vue'),
					// },
					// {
					// 	path: '/card/giftCard',
					// 	name: 'gift_card_index',
					// 	meta: { title: '发放记录', desc: '发放记录的统计管理中心', isKeepAlive: true },
					// 	component: () => import('@/layout/menu/threeMenu.vue'),
					// 	children: [
					// 		{
					// 			path: '/card/giftCard/giftCardPage',
					// 			name: 'gift_card_page',
					// 			meta: { title: '发放记录', requireAuth: true, isKeepAlive: true, source: 'giftCards', upLevel: 'gift_card_index' },
					// 			component: () => import('@/views/card/giftCard/components/giftCardPage/index.vue'),
					// 		},
					// 	],
					// },
					// {
					// 	path: '/card/giftCardLists',
					// 	name: 'gift_card_lists_index',
					// 	meta: { title: '领取详情', isKeepAlive: true, isHide: true, source: 'giftCards' },
					// 	component: () => import('@/views/card/giftCardLists/index.vue'),
					// },
					// {
					// 	path: '/card/touristCard',
					// 	name: 'tourist_card_index',
					// 	meta: { title: '预付卡', desc: '预付卡的创建统计管理中心', isKeepAlive: true },
					// 	component: () => import('@/layout/menu/threeMenu.vue'),
					// 	children: [
					// 		{
					// 			path: '/card/touristCard/touristCardPage',
					// 			name: 'tourist_card_page',
					// 			meta: { title: '预付卡', requireAuth: true, isKeepAlive: true, source: 'touristCards', upLevel: 'tourist_card_index' },
					// 			component: () => import('@/views/card/touristCard/components/touristCardPage/index.vue'),
					// 		},
					// 	],
					// },
				],
			},
			{
				path: '/finance',
				name: 'finance',
				meta: { title: '财务', requireAuth: true, icon: 'iconfont icondaizhifu' },
				component: () => import('@/layout/parent.vue'),
				children: [
					{
						path: '/finance/order',
						name: 'finance_order',
						meta: { title: '订单', desc: '店内订单、收款、收入等财务管理', isKeepAlive: true, source: 'orders' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/finance/order/all',
								name: 'finance_all',
								meta: { title: '全部订单', requireAuth: true, isKeepAlive: true, source: 'orders', upLevel: 'finance_order' },
								component: () => import('@/views/finance/order/all/index.vue'),
							},
							// {
							// 	path: '/finance/order/time',
							// 	name: 'finance_time',
							// 	meta: { title: '计时订单', requireAuth: true, isKeepAlive: true, source: 'orders', upLevel: 'finance_order' },
							// 	component: () => import('@/views/finance/order/time/index.vue'),
							// },
							{
								path: '/finance/order/long',
								name: 'finance_long',
								meta: { title: '长包订单', requireAuth: true, isKeepAlive: true, source: 'longTermOrders', upLevel: 'finance_order' },
								component: () => import('@/views/finance/order/long/index.vue'),
							},
							{
								path: '/finance/order/about',
								name: 'finance_about',
								meta: { title: '约球订单', requireAuth: true, isKeepAlive: true, source: 'appointments', upLevel: 'finance_order' },
								component: () => import('@/views/finance/order/about/index.vue'),
							},
						],
					},
					{
						path: '/finance/invoice',
						name: 'finance_invoice',
						meta: { title: '发票', isKeepAlive: true, source: 'invoices' },
						component: () => import('@/views/finance/invoice/index.vue'),
					},
					{
						path: '/finance/insurance',
						name: 'finance_insurance',
						meta: { title: '保险', isKeepAlive: true, source: 'insurances' },
						component: () => import('@/views/finance/insurance/index.vue'),
					},
					{
						path: '/finance/closeAnAccount',
						name: 'finance_close_an_account',
						meta: { title: '结算', isKeepAlive: true, source: 'settlements' },
						component: () => import('@/views/finance/closeAnAccount/index.vue'),
					},
					{
						path: '/finance/statistics',
						name: 'finance_statistics',
						meta: { title: '报表', source: 'report', isKeepAlive: true },
						component: () => import('@/views/finance/statistics/index.vue'),
					},
				],
			},
			{
				path: '/task',
				name: 'task',
				meta: { title: '营销', requireAuth: true, icon: 'iconfont iconshuju' },
				children: [
					{
						path: '/task/groupBuy',
						name: 'groupBuy',
						meta: { title: '多人团购', isKeepAlive: true, source: 'groupPurchase' },
						component: () => import('@/views/task/groupBuy/index.vue'),
					},
					{
						path: '/task/buyFree',
						name: 'buyFree',
						meta: { title: '买赠满减', isKeepAlive: true, source: 'buy' },
						component: () => import('@/views/task/buyFree/index.vue'),
					},
					{
						path: '/task/inviteShare',
						name: 'inviteShare',
						meta: { title: '邀请分享', isKeepAlive: true, source: 'taskShare' },
						component: () => import('@/views/task/inviteShare/index.vue'),
					},
					{
						path: '/task/coupon',
						name: 'coupon',
						meta: { title: '优惠券', isKeepAlive: true, source: 'taskCoupons', desc: '优惠券管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/task/coupon/couponList',
								name: 'couponList',
								meta: { title: '优惠券', source: 'taskCoupons', upLevel: 'coupon' },
								component: () => import('@/views/task/coupon/index.vue'),
							},
							{
								path: '/task/coupon/couponRecords',
								name: 'couponRecords',
								meta: { title: '领取记录', source: 'taskCoupons', upLevel: 'coupon' },
								component: () => import('@/views/task/couponRecord/index.vue'),
							},
						],
					},
					{
						path: '/task/configurationNew',
						name: 'configurationNew',
						meta: { title: '新用户礼包', isKeepAlive: true, source: 'newUserAwards' },
						component: () => import('@/views/task/configurationNew/index.vue'),
					},
					// {
					// 	path: '/task/configurationMessage',
					// 	name: 'configurationMessage',
					// 	meta: { title: '短信营销', isKeepAlive: true, source: 'marketingSms' },
					// 	component: () => import('@/views/task/configurationMessage/index.vue'),
					// },

					{
						path: '/task/configurationMessage',
						name: 'configurationMessage',
						meta: { title: '短信营销', isKeepAlive: true, desc: '操作简单、成本低廉、精准发送以及效果可控' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/task/configurationMessage/massHair',
								name: 'massHair',
								meta: { title: '发送短信', source: 'smsService', upLevel: 'configurationMessage' },
								component: () => import('@/views/task/massHair/index.vue'),
							},
							{
								path: '/task/configurationMessage/messagePrompt',
								name: 'messagePrompt',
								meta: { title: '短信应用', source: 'smsTemplate', upLevel: 'configurationMessage' },
								component: () => import('@/views/task/messagePrompt/index.vue'),
							},

							// {
							// 	path: '/task/configurationMessage/smsRechargeRecord',
							// 	name: 'smsRechargeRecord',
							// 	meta: { title: '购买记录', source: 'marketingSms', upLevel: 'configurationMessage' },
							// 	component: () => import('@/views/task/smsRechargeRecord/index.vue'),
							// },
						],
					},
					{
						path: '/task/configurationMessage/systemOrderConfirm',
						name: 'messgeOrderConfirm',
						meta: { title: '订单确认', source: 'smsService', isKeepAlive: true, isHide: true },
						component: () => import('@/views/giveService/systemOrderConfirm/index.vue'),
					},
					{
						path: '/task/configurationMessage/systemOrderDetail',
						name: 'messageOrderDetail',
						meta: { title: '订单明细', source: 'smsService', isKeepAlive: true, isHide: true },
						component: () => import('@/views/giveService/systemOrderDetail/index.vue'),
					},
					{
						path: '/task/faceVerification',
						name: 'faceVerification',
						meta: { title: '实人认证营销', isKeepAlive: true, source: 'realPersonAuth' },
						component: () => import('@/views/task/faceVerification/index.vue'),
					},
					{
						path: '/task/configurationCoin',
						name: 'configurationCoin',
						meta: { title: '运动币/积分', isKeepAlive: true, source: 'coin' },
						component: () => import('@/views/task/configurationCoin/index.vue'),
					},
				],
			},
			{
				path: '/activity',
				name: 'activity',
				meta: { title: '活动', requireAuth: true, icon: 'iconfont iconhuodongbiaoqian' },
				component: () => import('@/layout/parent.vue'),
				children: [
					{
						path: '/activity/activity',
						name: 'activity_index',
						meta: { title: '活动', isKeepAlive: true },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/activity/activity/activityList',
								name: 'activityList',
								meta: { title: '活动列表', source: 'marketActivities', upLevel: 'activity_index' },
								component: () => import('@/views/activity/activity/components/activities/index.vue'),
							},
							{
								path: '/activity/activity/activityOrder',
								name: 'activityOrder',
								meta: { title: '活动报名', source: 'marketActivityOrders', upLevel: 'activity_index' },
								component: () => import('@/views/activity/activity/components/order/index.vue'),
							},
						],
					},
					{
						path: '/activity/training',
						name: 'training_index',
						meta: { title: '训练', source: 'activities', isKeepAlive: true },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/activity/training/activityList',
								name: 'activities',
								meta: { title: '训练列表', source: 'activities', upLevel: 'training_index' },
								component: () => import('@/views/activity/training/components/activities/index.vue'),
							},
							{
								path: '/activity/training/activityOrder',
								name: 'activityRecords',
								meta: { title: '训练报名', source: 'activityRecords', upLevel: 'training_index' },
								component: () => import('@/views/activity/training/components/order/index.vue'),
							},
						],
					},
				],
			},
			{
				path: '/advertising',
				name: 'advertising',
				meta: { title: '广告', requireAuth: true, icon: 'iconfont iconzizhutuiguang' },
				component: () => import('@/layout/parent.vue'),
				children: [
					{
						path: '/advertising/banner',
						name: 'advertising_banner',
						meta: { title: '广告', desc: '广告管理', source: 'adBanners', isKeepAlive: true },
						component: () => import('@/views/advertising/banner/index.vue'),
					},
					// ** 有用页面 **
					// {
					// 	path: '/advertising/zone',
					// 	name: 'advertising_zone',
					// 	meta: { title: '广告版位', desc: '广告版位管理', source: 'advertisingZone', isKeepAlive: true },
					// 	component: () => import('@/views/advertising/zone/index.vue'),
					// },
					{
						path: '/advertising/articles',
						name: 'advertising_articles',
						meta: { title: '资讯', desc: '资讯管理', source: 'articles', isKeepAlive: true },
						component: () => import('@/views/advertising/articles/index.vue'),
					},
				],
			},
			{
				path: '/product',
				name: 'product',
				meta: {
					title: '商品',
					isKeepAlive: true,
					requireAuth: true,
					icon: 'iconfont iconlingshou2',
				},
				children: [
					{
						path: '/product/retailSale',
						name: 'retailSale_index',
						meta: {
							title: '零售',
							isKeepAlive: true,
							desc: '前台零售商品管理中心',
						},
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/product/retailSale/goods',
								name: 'retailSale_goods',
								meta: { title: '商品', source: 'resaleOffline', isKeepAlive: true, upLevel: 'retailSale_index' },
								component: () => import('@/views/product/retailSale/components/goods/index.vue'),
							},
							{
								path: '/product/retailSale/goodsOrder',
								name: 'retailSale_goodsOrder',
								meta: { title: '销售记录', source: 'resaleOfflineOrder', isKeepAlive: true, upLevel: 'retailSale_index' },
								component: () => import('@/views/product/retailSale/components/goodsOrder/index.vue'),
							},
							{
								path: '/product/retailSale/statistics',
								name: 'retailSale_goodsstatistics',
								meta: { title: '销售统计', source: 'resaleOfflineStatistic', isKeepAlive: false, requireAuth: false, upLevel: 'retailSale_index' },
								component: () => import('@/views/product/retailSale/components/statistics/index.vue'),
							},
						],
					},
					{
						path: '/product/online',
						name: 'online_index',
						meta: { title: '商城', isKeepAlive: true, desc: '线上商城商品管理中心' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/product/online/goods',
								name: 'online_goods',
								meta: { title: '商品管理', source: 'resaleOnline', isKeepAlive: true, upLevel: 'online_index' },
								component: () => import('@/views/product/online/components/goods/index.vue'),
							},
							{
								path: '/product/online/goodsOrder',
								name: 'online_goodsOrder',
								meta: { title: '销售记录', source: 'resaleOnlineOrder', isKeepAlive: true, upLevel: 'online_index' },
								component: () => import('@/views/product/online/components/goodsOrder/index.vue'),
							},
						],
					}
				],
			},
			{
				path: '/users',
				name: 'users',
				meta: { title: '用户', requireAuth: true, isKeepAlive: true, icon: 'iconfont iconyonghu2' },
				children: [
					{
						path: '/users/usersIndex',
						name: 'users_index',
						meta: { title: '用户', requireAuth: true, isKeepAlive: true, desc: '通过小程序及后台新增的所有用户列表' },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/users/allUser/allUserList',
								name: 'all_user_list',
								meta: { title: '全部用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 0 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
							{
								path: '/users/allUser/fullUserList',
								name: 'full_user_list',
								meta: { title: '整租用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 10 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
							{
								path: '/users/allUser/longUserList',
								name: 'long_user_list',
								meta: { title: '长包用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 11 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
							{
								path: '/users/allUser/looseUserList',
								name: 'loose_user_list',
								meta: { title: '散客用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 12 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
							{
								path: '/users/allUser/memberUserList',
								name: 'member_user_list',
								meta: { title: '月卡用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 21 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
							{
								path: '/users/allUser/depositUserList',
								name: 'deposit_user_list',
								meta: { title: '充值卡用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 22 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
							{
								path: '/users/allUser/balanceUserList',
								name: 'balance_user_list',
								meta: { title: '余额卡用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 23 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
							{
								path: '/users/allUser/counterUserList',
								name: 'counter_user_list',
								meta: { title: '次卡用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 24 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
							{
								path: '/users/allUser/vipUserList',
								name: 'vip_user_list',
								meta: { title: 'VIP卡用户', requireAuth: true, isKeepAlive: true, source: 'users', upLevel: 'users_index', aSubType: 25 },
								component: () => import('@/views/user/users/allUsers/index.vue'),
							},
						],
					},
					{
						path: '/users/userCrowd',
						name: 'user_crowd',
						meta: { title: '用户群', requireAuth: false, isKeepAlive: true, source: 'userGroup' },
						component: () => import('@/views/user/userCrowd/index.vue'),
					},
					{
						path: '/users/longUser',
						name: 'long_user',
						meta: { title: '长包客户', requireAuth: true, isKeepAlive: true, source: 'longTermUsers' },
						component: () => import('@/views/user/longUser/index.vue'),
					},
					{
						path: '/users/statistics',
						name: 'users_statistics',
						meta: { title: '报表', source: 'userReport', requireAuth: false, isKeepAlive: true },
						component: () => import('@/views/user/statistics/index.vue'),
					},
				],
			},

			{
				path: '/giveService',
				name: 'giveService',
				meta: { title: '服务', requireAuth: true, icon: 'iconfont iconfuwu2' },
				children: [
					// {
					// 	path: '/giveService/serviceMall',
					// 	name: 'serviceMall',
					// 	meta: { title: '服务商城', isKeepAlive: true },
					// 	component: () => import('@/views/giveService/serviceMall/index.vue'),
					// },
					{
						path: '/giveService/purchasedService',
						name: 'purchasedService',
						meta: { title: '已购服务', source: 'boughtServiceProduct', isKeepAlive: true },
						component: () => import('@/views/giveService/purchasedService/index.vue'),
					},
					{
						path: '/giveService/systemOrder',
						name: 'systemOrder',
						meta: { title: '系统订单', isKeepAlive: true, desc: '所有订单', source: 'serviceProductReceipt' },
						// component: () => import('@/views/giveService/systemOrder/index.vue'),
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/giveService/systemOrder/myOrder',
								name: 'myOrder',
								meta: { title: '我的订单', source: 'serviceProductReceipt', upLevel: 'systemOrder' },
								component: () => import('@/views/giveService/myOrder/index.vue'),
							},
							// {
							// 	path: '/giveService/systemOrder/renewOrder',
							// 	name: 'renewOrder',
							// 	meta: { title: '续费管理', source: 'serviceProductReceipt', upLevel: 'systemOrder' },
							// 	component: () => import('@/views/giveService/renewOrder/index.vue'),
							// },
							{
								path: '/giveService/systemOrder/extensionOrder',
								name: 'extensionOrder',
								meta: { title: '延期管理', source: 'serviceProductReceipt', upLevel: 'systemOrder' },
								component: () => import('@/views/giveService/extensionOrder/index.vue'),
							},
						],
					},
					{
						path: '/giveService/systemOrderConfirm',
						name: 'systemOrderConfirm',
						meta: { title: '订单确认', source: 'serviceProductReceipt', isKeepAlive: true, isHide: true },
						component: () => import('@/views/giveService/systemOrderConfirm/index.vue'),
					},
					{
						path: '/giveService/systemOrderDetail',
						name: 'systemOrderDetail',
						meta: { title: '订单明细', source: 'serviceProductReceipt', isKeepAlive: true, isHide: true },
						component: () => import('@/views/giveService/systemOrderDetail/index.vue'),
					},
				],
			},

			{
				path: '/account',
				name: 'account',
				meta: { title: '账号', requireAuth: true, icon: 'iconfont iconshezhi' },
				component: () => import('@/layout/parent.vue'),
				children: [
					{
						path: '/account/accountLevel',
						name: 'account_level',
						meta: { title: '账号权限', desc: '账号权限管理', isKeepAlive: true },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/account/accountLevel/admin',
								name: 'account_admin',
								meta: { title: '管理员', requireAuth: true, isKeepAlive: true, source: 'adminAccount', upLevel: 'account_level' },
								component: () => import('@/views/account/accountLevel/admin/index.vue'),
							},
							{
								path: '/account/accountLevel/role',
								name: 'account_role',
								meta: { title: '角色', requireAuth: true, isKeepAlive: true, source: 'accountRoles', upLevel: 'account_level' },
								component: () => import('@/views/account/accountLevel/role/index.vue'),
							},
						],
					},
					{
						path: '/account/testPlan',
						name: 'testPlan',
						meta: { title: '条款文案', source: 'agreement', isKeepAlive: true },
						component: () => import('@/views/account/testPlan/index.vue'),
					},
					{
						path: '/account/violationHandling',
						name: 'violation_handling',
						meta: { title: '违规抓拍', desc: '违规抓拍管理', isKeepAlive: true },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/account/excessFares',
								name: 'excess_fares',
								meta: { title: '违规记录', source: 'excessFares', isKeepAlive: true, upLevel: 'violation_handling' },
								component: () => import('@/views/account/violationHandling/excessFares/index.vue'),
							},
							{
								path: '/account/entrancePhotos',
								name: 'entrance_photos',
								meta: { title: '进店照片', source: 'escapeTicketEntrancePhotos', isKeepAlive: true, upLevel: 'violation_handling' },
								component: () => import('@/views/account/violationHandling/entrancePhotos/index.vue'),
							},
							{
								path: '/account/escapeLogs',
								name: 'escape_logs',
								meta: { title: '违规用户', source: 'escapeTicketUsers', isKeepAlive: true, upLevel: 'violation_handling' },
								component: () => import('@/views/account/violationHandling/escapeLogs/index.vue'),
							},
						],
					},
					{
						path: '/account/companies',
						name: 'companies',
						meta: { title: '公司管理', source: 'company', isKeepAlive: true },
						component: () => import('@/views/account/companies/index.vue'),
					},
					{
						path: '/account/arenas',
						name: 'arenas',
						meta: { title: '门店管理', source: 'arenas', isKeepAlive: true },
						component: () => import('@/views/account/arenas/index.vue'),
					},
					{
						path: '/account/tripartite',
						name: 'tripartite',
						meta: { title: '三方核销', desc: '抖音、美团、大众点评团购商品核销配置', isKeepAlive: true },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/account/tripartite/douyinArenas',
								name: 'account_douyin_arenas',
								meta: { title: '抖音门店', isKeepAlive: true, source: 'dyArena', upLevel: 'tripartite' },
								component: () => import('@/views/account/tripartite/douyinArenas/index.vue'),
							},
							{
								path: '/account/tripartite/douyinGoods',
								name: 'account_douyin_goods',
								meta: { title: '抖音商品', isKeepAlive: true, source: 'dyGoods', upLevel: 'tripartite' },
								component: () => import('@/views/account/tripartite/douyinGoods/index.vue'),
							},
							{
								path: '/account/tripartite/meituanArenas',
								name: 'account_meituan_arenas',
								meta: { title: '美团点评门店', isKeepAlive: true, source: 'mtArena', upLevel: 'tripartite' },
								component: () => import('@/views/account/tripartite/meituanArenas/index.vue'),
							},
							{
								path: '/account/tripartite/meituanGoods',
								name: 'account_meituan_goods',
								meta: { title: '美团点评商品', isKeepAlive: true, source: 'mtGoods', upLevel: 'tripartite' },
								component: () => import('@/views/account/tripartite/meituanGoods/index.vue'),
							},
						]
					},
					{
						path: '/account/configuration',
						name: 'systemConfiguration',
						meta: { title: '系统配置', source: 'systemConfiguration', isKeepAlive: true, requireAuth: false },
						component: () => import('@/views/account/systemConfiguration/index.vue'),
					},
					{
						path: '/account/qyd',
						name: 'qyd',
						meta: { title: '趣运动管理', desc: '趣运动场地状态同步管理', isKeepAlive: true },
						component: () => import('@/layout/menu/threeMenu.vue'),
						children: [
							{
								path: '/account/qyd/basicSetting',
								name: 'qyd_basic_setting',
								meta: { title: '基础设置', isKeepAlive: true, source: 'qydSetting', upLevel: 'qyd' },
								component: () => import('@/views/account/qyd/basicSetting/index.vue'),
							},
							{
								path: '/account/qyd/court',
								name: 'qyd_court',
								meta: { title: '趣运动场地管理', isKeepAlive: true, source: 'qydCourt', upLevel: 'qyd' },
								component: () => import('@/views/account/qyd/court/index.vue'),
							},
						]
					},
					{
						path: '/account/actionLogs',
						name: 'action_logs',
						meta: { title: '操作日志', source: 'accountLogs', isKeepAlive: true },
						component: () => import('@/views/account/actionLogs/index.vue'),
					},
				],
			},
			{
				path: '/notifications',
				name: 'notifications',
				meta: { title: '系统通知', requireAuth: false, isHide: true, icon: 'iconfont iconshezhi' },
				component: () => import('@/layout/parent.vue'),
				children: [
					{
						path: '/notifications/all',
						name: 'notificationsAll',
						meta: { title: '系统通知', requireAuth: false, isKeepAlive: true },
						component: () => import('@/views/notifications/all/index.vue'),
					},
				],
			},
		],
	},
];

export default indexRouter;
