<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'App',
})
</script>
<template>
	<router-view></router-view>
</template>

<script setup lang="ts" name="App">
import setIntroduction from '@/utils/setIconfont';
import { onBeforeMount, onMounted, onUnmounted, watch } from 'vue';
import { useTitle } from '@/utils/other';
import { useRoute } from 'vue-router';
import { routerListHook } from '@/stores/modules/routerList';
import { userInfoHook } from '@/stores/modules/userInfo';
import { themeConfig } from '@/stores/modules/themeConfig';
import { getCurrentTimeAfter } from '@/utils/formatTime'

onBeforeMount(() => {
	// 设置批量第三方 icon 图标
	setIntroduction.cssCdn();
	// 设置批量第三方 js
	setIntroduction.jsCdn();

	themeConfig().refreshTime = getCurrentTimeAfter(1)
});

onMounted(() => { });

onUnmounted(() => { });

const route = useRoute();

// 获取当前路由的按钮权限
const getCurrentItem = () => {
	const { items } = userInfoHook()
	const { source = '' } = route.meta

	const currentItem = items?.find((item: any) => item.alias === source)
	routerListHook().btnAuths = currentItem?.actions || []
}

// 监听路由的变化，设置网站标题
watch(
	() => route.path,
	() => {
		useTitle();
		// 保存当前路由的source
		getCurrentItem()
	},
	{
		deep: true,
	}
);

</script>

<style>
.ccc {
	color: red;
}
</style>
