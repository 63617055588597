<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QiSearch',
})
</script>
<template>
	<div>
		<el-form ref="formTep" :inline="false" :label-position="labelPosition" :model="ruleForm">
			<el-row class="search_box">
				<div v-for="(formItem, index) in formBaseData" :key="formItem.key" :class="formBaseData.length - 1 === index ? '' : 'mr40'">
					<el-form-item v-if="!formItem.isHide" :prop="formItem.key" :label="formItem.label + ':'">
						<component :is="transType(formItem.type)" :rule-form="ruleForm" :form-item="formItem" :is-rest="isRest" @enter="clickSearch"> </component>
					</el-form-item>
				</div>
				<div class="flex flex-align-center mb18 ml25">
					<div class="btn btn_search" @click="clickSearch">搜索</div>
					<div class="btn btn_reset ml8" @click="clickResetForm">重置</div>
					<slot name="btns"></slot>
				</div>
			</el-row>
		</el-form>
	</div>
</template>

<script lang="ts" setup name="QiSearch">
import { reactive, ref, onMounted, nextTick } from 'vue';
import QiSelect from '../eleComponents/QiSelect.vue';
import QiInput from '../eleComponents/QiInput.vue';
import QiDatePicker from '../eleComponents/QiDatePicker.vue';
import utils from '@/utils/index';
import { formBaseInter } from './type'

const emit = defineEmits(['search']);
const props = defineProps({
	labelPosition: {
		// 表单域标签的位置
		type: String,
		default: 'right',
	},
	labelWidth: {
		// 表单域标签的位置
		type: Number,
		default: 100,
	},
	formBaseData: {
		// 表单的配置项
		type: Array<formBaseInter>,
		default():formBaseInter[]  {
			return [];
		},
	},
});

const isRest = ref(false);
const formTep = ref();

const ruleForm: any = reactive({});
const formKeys: string[] = reactive([]);

onMounted(() => {
	init();
});

// 设置表单的数据和初始值
const init = () => {
	if (props.formBaseData.length > 0) {
		for (const iterator of props.formBaseData) {
			let initVal = iterator.initVal;
			if (utils.isUndefined(initVal)) {
				initVal = null;
			}
			formKeys.push(iterator.key);
			ruleForm[iterator.key] = initVal;
		}
	}
};

const transType = (type: number): any => {
	let componentName: any = null;
	switch (type) {
		case 0:
			componentName = QiInput;
			break;
		case 1:
			componentName = QiSelect;
			break;
		case 2:
			componentName = QiDatePicker;
			break;
	}
	return componentName;
};

const clickSearch = () => {
	emit('search', deleteInvalid(ruleForm));
};

const clickResetForm = () => {
	init();
	isRest.value = true;
	nextTick(() => {
		isRest.value = false;
		emit('search', ruleForm);
	});
};

// 删除对象里面值为null和undefined和''的这些无效的字段
const deleteInvalid = (obj: any) => {
	Object.keys(obj).forEach((item) => {
		if ((!obj[item] && obj[item] != 0) || obj[item] === '') {
			delete obj[item];
		}
	});
	return obj;
};
</script>

<style lang="scss" scoped>
/* elInput 下拉选择框
------------------------------- */
:deep(.el-input) {
	width: 240px;
	height: 40px;
}

/* elForm 表单
------------------------------- */
.el-form-item {
	align-items: center;
}

:deep(.el-date-editor) {
	width: 280px !important;
	height: 40px;
}
.btn {
	width: 60px;
	height: 32px;
	text-align: center;
	line-height: 32px;
	border-radius: 4px;
	font-size: var(--no7-theme-fz);
	cursor: pointer;

	&.btn_search {
		background: var(--no7-theme-color);
		color: #fff;
	}

	&.btn_reset {
		border: 1px solid var(--no7-main-line);
		color: var(--no7-title-color);
	}
}
</style>
