<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QiInput',
})
</script>
<template>
	<el-input
		v-model="inputVal"
		:placeholder="placeholder"
		:type="inputType"
		:rows="row"
		show-word-limit
		:maxlength="maxlength"
		clearable
		:show-password="formItem.inputType === 'password'"
		:disabled="formItem.isDisabled"
		@input="input"
		@focus="inputFocus"
		@blur="inputBlur"
		@keyup.enter.native="submitForm()"
	>
		<template v-if="formItem.isShowSlot" #append>
			<span>{{ formItem.showSlotName }}</span>
		</template>
	</el-input>
</template>

<script lang="ts" setup name="QiInput">
import { ref, computed, watch, onMounted } from 'vue';
import { themeConfigHook } from '@/stores/modules/themeConfig';
import { useRoute } from 'vue-router';

const route = useRoute()

const props = defineProps({
	ruleForm: {
		type: Object,
		default: () => {},
	},
	formItem: {
		type: Object,
		default: () => {},
	},
	modelValue: {
		type: [String, Number],
		default: '',
	},
	isRest: Boolean,
});

const emits = defineEmits(['update:modelValue', 'enter']);

const inputFocus = () => {
	// 针对操作中心的扫码器输入框
	if (route.name === 'operationCenter') themeConfigHook().isScannerinputFocus = false
}

const inputBlur = () => {
	// 针对操作中心的扫码器输入框
	if (route.name === 'operationCenter') themeConfigHook().isScannerinputFocus = true
}

watch(
	() => props.modelValue,
	() => {
		inputVal.value = props.modelValue + '';
	}
);

watch(
	() => props.isRest,
	() => {
		if (props.isRest) {
			inputVal.value = props.formItem.initVal;
		}
	}
);

const submitForm = () => {
	emits('enter')
}

onMounted(() => {
	init();
});

// 初始参数
const init = () => {
	if (props.formItem.initVal) {
		inputVal.value = props.formItem.initVal;
	}
};

const placeholder = computed(() => {
	const item = props.formItem;
	return item.placeholder ? item.placeholder : `请输入${item.label}`;
});

const inputType = computed(() => {
	const item = props.formItem;
	return item.inputType ? item.inputType : 'text';
});

const row = computed(() => {
	const item = props.formItem;
	return item.row ? item.row : 4;
});

const maxlength = computed(() => {
	const item = props.formItem;
	return item.maxlength ? item.maxlength : null;
});

const inputVal = ref('');

const input = (val: string) => {
	// eslint-disable-next-line vue/no-mutating-props
	props.ruleForm[props.formItem.key] = val.trim();
	emits('update:modelValue', val.trim());
};
</script>
