import { createApp } from 'vue';
import App from './App.vue';
import { setupStore } from '@/stores';
import router from '@/router/index';
import ElementPlus from 'element-plus';
import locale from 'element-plus/lib/locale/lang/zh-cn';
import { MotionPlugin } from '@vueuse/motion';
import { elSvg } from '@/utils/other';
// import QTable from '@/components/table/qiTable.vue';
import QSearch from '@/components/QiSearch/index.vue';
import dialogSearch from '@/components/QiSearch/dialogSearch.vue';
import { setDirectives } from '@/directives/index';

// element-plus
import 'element-plus/dist/index.css'
// reset style sheet
import '@/styles/index.scss';
// iconfont
import '@/assets/font/iconfont.css';

const app = createApp(App);

setupStore(app);
elSvg(app);
setDirectives(app);
app.use(router);

// app.component('QTable', QTable);
app.component('QSearch', QSearch);
app.component('dialogSearch', dialogSearch);

app.use(ElementPlus, { locale }).use(MotionPlugin).mount('#app');
