<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QiDatePicker',
})
</script>
<template>
	<el-date-picker
		v-model="timeInterval"
		:type="dateType"
		size="default"
		:placeholder="placeholder"
		start-placeholder="开始日期"
		end-placeholder="结束日期"
		:format="format"
		:value-format="valueFormat"
		range-separator="至"
		:clearable="clearable"
		@change="dataChange"
		:style="{ 'max-width': pickerWidth + 'px', 'box-sizing': 'border-box' }"
	>
	</el-date-picker>
</template>

<script lang="ts" setup name="QiDatePicker">
import { computed, onMounted, ref, watch } from 'vue';

const props = defineProps({
	ruleForm: {
		type: Object,
		default: () => {},
	},
	formItem: {
		type: Object,
		default: () => {},
	},
	modelValue: {
		type: [String, Number, Array],
		default: '',
	},
	from: String,
	to: String,
	isRest: Boolean,
	pickerWidth: {
		type: Number,
		default: 320,
	},
});

const emits = defineEmits(['update:modelValue', 'change', 'update:to', 'update:from']);

const timeInterval: any = ref([]);

const placeholder = computed(() => {
	const item = props.formItem;
	return item.placeholder ? item.placeholder : `请选择${item.label}`;
});

const dateType = computed(() => {
	const item = props.formItem;
	return item.dateType ? item.dateType : `date`;
});

const clearable = computed(() => {
	const item = props.formItem;
	return !item.clearable
});

watch(
	() => props.modelValue,
	() => {
		timeInterval.value = props.modelValue;
	},
	{
		immediate: true,
	}
);

watch(
	() => props.isRest,
	() => {
		if (props.isRest) {
			timeInterval.value = props.formItem.initVal;
		}
	}
);

watch(
	() => props.to,
	() => {
		timeInterval.value[1] = props.to;
	}
);

watch(
	() => props.from,
	() => {
		timeInterval.value[0] = props.from;
	}
);

onMounted(() => {
	init();
});

// 初始参数
const init = () => {
	if (props.formItem.initVal) {
		timeInterval.value = props.formItem.initVal;
	}
};

const format = computed(() => {
	const item = props.formItem;
	return item.format ? item.format : `YYYY-MM-DD`;
});

const valueFormat = computed(() => {
	const item = props.formItem;
	return item.valueFormat ? item.valueFormat : `YYYY-MM-DD`;
});

const dataChange = (data: any) => {
	// eslint-disable-next-line vue/no-mutating-props
	if (data == null) data = props.formItem.dateType == 'daterange' ? ['', ''] : '';
	if (props.ruleForm) props.ruleForm[props.formItem.key] = data;
	emits('update:modelValue', data);

	emits('change', data);

	emits('update:from', data[0]);
	emits('update:to', data[1]);
};

// const changeVal = (data: any) => {
//   console.log(data)
//   // eslint-disable-next-line vue/no-mutating-props
//   props.ruleForm[props.formItem.key] = data
// }
</script>
