import { store } from '@/stores';
import { routerInterface } from './types';
import { defineStore } from 'pinia';
import { RouteRecordRaw } from 'vue-router';

export const routerList = defineStore({
	id: 'routerList',
	state: (): routerInterface<RouteRecordRaw[]> => ({
		routes: [],
		allOneRoutes: [],
		oneRoutes: [],
		twoRoutes: [],
		arenaTwoRoutes: [],
		btnAuths: [],
		currentTwoRoute: {},
		isCloseMenu: false,
	}),
	getters: {},
	actions: {
		setCloseMenu() {
			this.isCloseMenu = !this.isCloseMenu;
		},
	},
});

export function routerListHook() {
	return routerList(store);
}
