import { routerListHook } from '@/stores/modules/routerList';
import { userInfoHook } from '@/stores/modules/userInfo';

export default function (action: string, modelName: string) {
  let btnAuths = []
    const { items } = userInfoHook()

  if (modelName === '_') {
    btnAuths = routerListHook().btnAuths
  } else {
    const currentItem = items.find((item: any) => item.alias === modelName)
    btnAuths = currentItem ? currentItem.actions : []
  }

  let auth: any = null

  if (action === 'update') {
    const updateBtn = btnAuths.find(auth => auth.action === 'update')
    const editBtn = btnAuths.find(auth => auth.action === 'edit')

    auth = updateBtn || editBtn
  } else {
    auth = btnAuths.find(auth => auth.action === action)
  }

  return !!auth
}