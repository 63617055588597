export default {
	jugeType(obj: any) {
		const getType = Object.prototype.toString;
		return getType.call(obj);
	},

	isArr(target: any) {
		return this.jugeType(target) === '[object Array]';
	},

	isObj(target: any) {
		return this.jugeType(target) === '[object Object]';
	},

	isString(target: any) {
		return this.jugeType(target) === '[object String]';
	},

	isNumber(target: any) {
		return this.jugeType(target) === '[object Number]';
	},

	isBoolean(target: any) {
		return this.jugeType(target) === '[object Boolean]';
	},

	isUndefined(target: any) {
		return this.jugeType(target) === '[object Undefined]';
	},

	isNull(target: any) {
		return this.jugeType(target) === '[object Null]';
	},

	isFunction(target: any) {
		return this.jugeType(target) === '[object Function]';
	},

	isPromise(target: any) {
		return this.jugeType(target) === '[object Promise]';
	},

	deepCopy(obj: any): any {
		let newObj = obj instanceof Array ? [] : {}
		for (let i in obj) {
			// for...in 会遍历原型上的属性，此处只拷贝obj对象自身的属性
			if (obj.hasOwnProperty(i)) {
				let type = Object.prototype.toString.call(obj[i])
				if (typeof obj[i] == 'object') {
					// 拷贝的值为对象，则需要深拷贝
					if (type == '[object Date]') {
						newObj[i] = new Date(obj[i].valueOf())
					} else if (type == '[object RegExp]') {
						// 正则对象
						let pattern = obj[i].valueOf()
						let flags = ''
						flags += pattern.global ? 'g' : ''
						flags += pattern.ignoreCase ? 'i' : ''
						flags += pattern.multiline ? 'm' : ''
						newObj[i] = new RegExp(pattern.source, flags)
					} else if (type == '[object Array]' || type == '[object Object]') {
						// 数组或对象
						newObj[i] = this.deepCopy(obj[i])
					} else {
						// 包装对象Number，String，Boolean
						if (type != '[object Null]' && type != '[object Undefined]') newObj[i] = obj[i].valueOf()
					}
				} else if (typeof obj[i] == 'function') {
					// 函数
					newObj[i] = new Function('return ' + obj[i].toString())()
				} else {
					// 拷贝的值为原始值，则直接复制该值
					newObj[i] = obj[i]
				}
			}
		}
		return newObj
	}
};
