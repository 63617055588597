import type { App } from 'vue';
import { routerListHook } from '@/stores/modules/routerList';
import { userInfoHook } from '@/stores/modules/userInfo';

export function setDirectives(app: App) {
  // 注册一个全局判断按钮权限的指令
  app.directive('hasPermission', {
    mounted(el, binding) {
      // 获取指令的值
      const { value, arg } = binding
      let { btnAuths } = routerListHook()
      const { items } = userInfoHook()
      // arg有值表示通过其他页面的路由权限进行判断
      if (arg) {
        const currentItem = items.find((item: any) => item.alias === arg)
        btnAuths = currentItem ? currentItem.actions : []
      }

      let auth: any = null

      if (value === 'update') {
        const updateBtn = btnAuths.find(auth => auth.action === 'update')
        const editBtn = btnAuths.find(auth => auth.action === 'edit')
    
        auth = updateBtn || editBtn
      } else {
        auth = btnAuths.find(auth => auth.action === value)
      }

      if (auth) return

      el.parentNode && el.parentNode.removeChild(el)
    },
  }),
    app.directive('zoom', {
      mounted(el, binding) {
        // 获取dom的中心坐标
        function get_middle_coordinate(dom: { width: number; left: number; height: number; top: number; }) {
          const X = dom.width / 2 + dom.left
          const Y = dom.height / 2 + dom.top
          return {
            X, Y
          }
        }

        el.onmousedown = function (e: { stopPropagation: () => void; currentTarget: { parentElement: any; }; clientX: number; clientY: number; }) {
          e.stopPropagation && e.stopPropagation()

          // 获取父节点
          const parentEl = e.currentTarget.parentElement

          const arg: any = binding.arg || {}

          // 获取相关dom信息
          const customBoxInfo = parentEl.getBoundingClientRect()
          const zoomBtnInfo = el.getBoundingClientRect()

          // 获取每次的初始值
          let initWidth = 0
          let initHeight = 0
          let initTop = 0
          let initLeft = 0

          // 兼容旋转效果，如果父节点旋转了角度。获取样式的宽高会有bug
          if (arg.width) {
            initWidth = arg.width
            initHeight = arg.height
            initTop = arg.top
            initLeft = arg.left
          } else {
            initWidth = customBoxInfo.width
            initHeight = customBoxInfo.height
            initTop = parseInt(parentEl.style.top)
            initLeft = parseInt(parentEl.style.left)
          }

          // 最终的位子
          let endHeight = 0
          let endWidth = 0
          let endTop = 0
          let endLeft = 0

          // 获取到盒子的中心坐标
          const { X: boxX, Y: boxY } = get_middle_coordinate(customBoxInfo)

          // 获取右下角缩放按钮的坐标
          const { X: btnX, Y: btnY } = get_middle_coordinate(zoomBtnInfo)

          // 按下时鼠标的偏移量
          const disX = e.clientX - btnX
          const disY = e.clientY - btnY


          document.onmousemove = e => {
            // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            const mouseLeft = e.clientX - disX
            const mouseTop = e.clientY - disY

            // 开始和结束时的线长度
            const startDistance = Math.sqrt(Math.pow(btnX - boxX, 2) + Math.pow(btnY - boxY, 2))
            const endDistance = Math.sqrt(Math.pow(mouseLeft - boxX, 2) + Math.pow(mouseTop - boxY, 2))

            // 最终宽高
            endHeight = Math.round(initHeight * (endDistance / startDistance))
            endWidth = Math.round(initWidth * (endDistance / startDistance))


            // 最终位置
            endTop = Math.round(initTop + ((initHeight - endHeight) / 2))
            endLeft = Math.round(initLeft + ((initWidth - endWidth) / 2))

            // 操作dom
            // parentEl.style.width = endWidth + 'px'
            // parentEl.style.height = endHeight + 'px'
            // parentEl.style.top = endTop + 'px'
            // parentEl.style.left = endLeft + 'px'
            // 将位子信息传出去
            binding.value({ width: endWidth, height: endHeight, top: endTop, left: endLeft })
          }

          document.onmouseup = () => {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        }
      }
    }),
    app.directive('move', {
      mounted(el, binding) {
        el.onmousedown = (e: { stopPropagation: () => void; clientX: number; clientY: number; }) => {
          e.stopPropagation && e.stopPropagation()

          let offsetX = e.clientX - el.offsetLeft
          let offsetY = e.clientY - el.offsetTop

          document.onmousemove = e => {
            let l = e.clientX - offsetX;
            let t = e.clientY - offsetY;

            el.style.left = l + 'px'
            el.style.top = t + 'px'

            binding.value && binding.value({ top: t, left: l })
          }

          document.onmouseup = () => {
            document.onmousemove = null
            document.onmouseup = null
          }
        }
      }
    })
}