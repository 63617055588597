import SvgIcon from '@/components/svgIcon/index.vue';
import * as svg from '@element-plus/icons-vue';
import type { App } from 'vue';
import { nextTick } from 'vue';
import router from '@/router/index';
import { themeConfigHook } from '@/stores/modules/themeConfig';

/**
 * 导出全局注册 element plus svg 图标
 * @param app vue 实例
 * @description 使用：https://element-plus.gitee.io/zh-CN/component/icon.html
 */
export function elSvg(app: App) {
	const icons = svg as any;
	for (const i in icons) {
		app.component(`ele-${icons[i].name}`, icons[i]);
	}
	app.component('SvgIcon', SvgIcon);
}

/**
 * @method const title = useTitle(); ==> title()
 */

export function useTitle() {
	nextTick(() => {
		let globalTitle: string = themeConfigHook().uiConfig.brandName || '商家后台管理平台'
		const { meta } = router.currentRoute.value;

		document.title = `${meta.title} - ${globalTitle}` || globalTitle;
	});
}
