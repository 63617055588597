import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ElMessage, ElLoading } from 'element-plus';
import qs from 'qs';
import md5 from 'blueimp-md5';
import utils from '@/utils';
import { Local } from '@/utils/storage';
// import { WSService } from './ws/ws';

let loading: any = null;
interface myConfig extends AxiosRequestConfig {
	headers: any;
	changeHead?: boolean;
	needFormData?: boolean;
	noLoading?: boolean;
	method?: any;
	isZip?: boolean;
	isBody?: boolean;
}

const CancelToken = axios.CancelToken

const urlSet = new Set([])

const getUrl = config => {
  const { method, url, data = {}, params = {}, baseURL } = JSON.parse(
    JSON.stringify(config)
  )
  const curls = [
    url.split(baseURL).splice(-1, 1)[0],
    method,
    JSON.stringify(data),
    JSON.stringify(params)
  ]
    .join('')
    .replace(/['|{|}|:|\/|"|,|-|\\| |\-|\.|\_|\;|\[|\]]/g, '')
  return curls
}
const timer = {}

const deleteUrl = (url, delay = 300) => {
  clearTimeout(timer[url])
  timer[url] = setTimeout(_ => {
    urlSet.delete(url)
    timer[url] = undefined
  }, delay)
}

// 创建一个axios的实例
const service = axios.create({
	baseURL: import.meta.env.VITE_API_URL as any,
	timeout: 240000,
	headers: { 'Content-Type': 'application/json' },
});

const openCardUrls = ['/adm_arena/v4/card_receipts', '/adm_arena/v4/counter_card_receipts', '/adm_arena/v4/deposit_card_receipts', '/adm_arena/v4/svip_card_receipts', '/adm_arena/v4/balance_card_receipts']

// 请求拦截器
service.interceptors.request.use(
	(config: myConfig) => {
		const urls = getUrl(config)
		// 目前重复请求只针对开卡部分
		if (urlSet.has(urls) && openCardUrls.includes(config.url)) {
      config.cancelToken = new CancelToken(function executor(c) {
        // 本次axios请求的配置添加cancelToken
        // 如果触发频繁 则将延时增加至1s
        deleteUrl(urls, 500)
        c(`${config.url}请求太频繁,请稍候再试!`)
        return
      })
      // 取消请求
    } else {
      // 存储url
      urlSet.add(urls)
      // 1s 后强制删除
      deleteUrl(urls, 1000)
    }

		// 过滤掉请求参数中的null和undefined  (formData不过滤)
		for (const parameter in config.data) {
			if (!config.changeHead && (utils.isNull(config.data[parameter]) || utils.isUndefined(config.data[parameter]))) {
				delete config.data[parameter];
			}
		}
		if (['post', 'patch', 'put'].includes(config.method) && config.data !== null) {
			//(formData不处理)
			if (!config.changeHead) config.data = JSON.stringify(config.data);
		} else if (['get', 'delete'].includes(config.method)) {
			// config.data = qs.stringify(config.data);
			if (config.isBody) {
				config.data = JSON.stringify(config.data);
			} else {
				config.url = appendParams(apiHost(config.url), config.data);
				config.data = _formatParams(config.data);
			}
		}

		config.headers = getHeaders(config.data);

		if (config.changeHead) {
			config.headers['Content-Type'] = 'multipart/form-data';
		}

		if (config.needFormData) {
			config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
			config.data = qs.stringify(config.data);
		}

		if (config.isZip) {
			config.responseType = 'blob';
			config.headers['Content-Type'] = 'application/zip;charset=utf-8';
		}

		if (JSON.stringify(config.data) === '{}' && config.headers['Content-Type'] !== 'multipart/form-data') {
			config.data = null;
		}

		if (!config.noLoading) {
			loading = ElLoading.service({
				lock: true,
				text: 'Loading',
				background: 'rgba(0, 0, 0, 0)'
			});
		}
		// console.log(config);
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

service.interceptors.response.use(
	(response) => {
		if (loading) loading.close();
		return response;
	},
	(error) => {
		if (loading) loading.close();

		if (error.response) {
			handlerError(error);
		} else {
			ElMessage({
				message: error,
				type: 'error',
			});
		}
		return Promise.reject(error);
	}
);

export const http = {
	async GET<T = any, P = any>(url: string, data: P, config?: myConfig): Promise<T> {
		const res: AxiosResponse<T> = await service.get<T>(url, Object.assign({ data }, config));
		return res.data;
	},
};

export async function GET<T = any, P = any>(url: string, data?: P, config?: any): Promise<T> {
	const res: AxiosResponse<T> = await service.get<T>(url, Object.assign({ data }, config));
	return res.data;
}

export async function GETLIST<T = any, P = any>(url: string, data?: P): Promise<[AxiosResponse['headers'], AxiosResponse['data']]> {
	const res: any = await service.request<T>({
		method: 'GET',
		url,
		data,
		noLoading: true
	});
	return [res.headers, res.data];
}

export async function POST<T = any, P = any>(url: string, data: P, config?: myConfig): Promise<T> {
	const res: AxiosResponse<T> = await service.post<T>(url, data, config);
	return res.data;
}

export async function PUT<T = any, P = any>(url: string, data: P, config?: myConfig): Promise<T> {
	const res: AxiosResponse<T> = await service.put<T>(url, data, config);
	return res.data;
}

export async function downloadZip<T = any, P = any>(url: string, data?: P, config?: myConfig): Promise<any> {
	const res: any = await service.get<T>(url, Object.assign({ data }, config));
	const zipInfo: any = [res.data, res.headers];
	return new Promise(function (resolve, reject) {
		const blob = new Blob([zipInfo[0]], {
			type: 'application/zip;charset=utf-8',
		});
		const respHeaders: any = zipInfo[1];

		const href = window.URL.createObjectURL(blob); // 创建下载的链接
		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = href;
		link.download = respHeaders['x-filename'] ? respHeaders['x-filename'] : 'foo.zip'; // 下载后文件名
		document.body.appendChild(link);
		link.click(); // 点击下载
		document.body.removeChild(link); // 下载完成移除元素
		window.URL.revokeObjectURL(href); // 释放掉blob对象

		return resolve('ok');
	});
}

// export const GET = (url: string, params?: any, config?: myConfig) =>
// 	service
// 		.get(
// 			url,
// 			Object.assign(
// 				{
// 					data: params,
// 				},
// 				config
// 			)
// 		)
// 		.then((res) => {
// 			return res.data
// 		});

// export const GETLIST = (url: string, params?: any) => {
// 	return service
// 		.request({
// 			method: 'GET',
// 			url,
// 			data: params,
// 		})
// 		.then((res) => {
// 			return [res.headers, res.data];
// 		});
// };

// export const POST = (url: string, params: any, config?: any) => {
// 	return service.post(url, params, config).then((res) => {
// 		return res.data;
// 	});
// };

// export const PUT = (url: string, params: any, config?: any) => {
// 	return service.put(url, params, config).then((res) => res.data);
// };

export const PATCH = (url: string, data: any, config?: any) => {
	return service.patch(url, data, config).then((res) => res.data);
};

export const DELETE = (url: string, data: any, config?: any) => {
	return service
		.delete(
			url,
			Object.assign(
				{
					data: data,
				},
				config
			)
		)
		.then((res) => res.data);
};

export const ALL = (promiseArr: unknown[]) => axios.all(promiseArr);

export function getHeaders(data: any) {
	const headers: any = {
		'Content-Type': 'application/json',
	};
	const tu = new Date().getTime() / 1000;
	const t = parseInt(String(tu), 10);
	// const suffix = '&key=' + import.meta.env.VITE_APP_SECRET + '&t=' + t;
	const sign = md5((data || '') + _signSuffix(t));
	// 添加token
	if (Local.get('token')) {
		headers['x-access-token'] = Local.get('token');
	}
	headers['x-access-k'] = 'bp4';
	headers['x-access-v'] = sign;
	headers['x-access-t'] = String(t);
	return headers;
}

function handlerError(error: any) {
	if (error && error.response) {
		if (error.response.status === 400) {
			error.message = error.response.data.message || '请求错误';
		} else if (error.response.status === 401) {
			// 用户身份已过期，请重新登录
			error.message = '未授权，请登录';
			window.location.href = '#/login';
			// WSService.getInstance().close();
		} else if (error.response.status === 403) {
			// 无权限 TODO
			error.message = '拒绝访问';
			window.location.href = '#/error/403';
		} else if (error.response.status === 404) {
			// not found TODO
			// window.location.href = "/#/404";
			error.message = `请求地址出错: ${error.response.config.url}`;
		} else if (error.response.status === 408) {
			error.message = '请求超时';
		} else if (error.response.status === 500) {
			error.message = error.response.data.message || '服务器内部错误';
		} else if (error.response.status === 501) {
			error.message = '服务未实现';
		} else if (error.response.status === 502) {
			error.message = '网关错误';
		} else if (error.response.status === 503) {
			error.message = '服务不可用';
		} else if (error.response.status === 504) {
			error.message = '网关超时';
		} else {
			error.message = `连接出错(${error.response.status})!`;
		}

		ElMessage({
			message: error.response.data.message || error.message || '出现错误，请稍后再试',
			type: 'error',
		});
	}
}

// 获取请求路径
export function apiHost(url: string): string {
	const apiHost: string = import.meta.env.VITE_API_URL ? import.meta.env.VITE_API_URL : '';
	if (url.match(/^http/)) {
		return url;
	}
	return url.indexOf('/') === 0 ? apiHost + url : apiHost + '/' + url;
}

function _signSuffix(t: number) {
	const secret = _getSecret();
	return '&' + _code2str(107) + _code2str(101) + _code2str(121) + '=' + secret + '&' + _code2str(116) + '=' + t;
}

function _getSecret(): string {
	let s: string = import.meta.env.VITE_APP_SECRET;
	s += import.meta.env.MODE === 'production' ? 'prod' : '';
	// s += 'prod';
	return s;
}

function _code2str(code: number): string {
	return String.fromCharCode(code);
}

// 将请求参数拼接到请求链接后面
export function appendParams(uri: string, params: any): string {
	if (uri === '') {
		return '';
	}
	const query = _formatParams(params);

	if (query !== '') {
		uri += -1 === uri.indexOf('?') ? '?' : '&';
		uri += query;
	}
	// console.log(uri);
	return uri;
}

function _formatParams(params: any): string {
	const p: string[] = [];
	for (const k in params) {
		if (params[k] !== '' && params[k] !== undefined && params[k] !== null) {
			p.push(k + '=' + encodeURIComponent(params[k]).replace(/'/g, ''));
		}
	}
	return p.join('&');
}
