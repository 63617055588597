<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'QiSearch',
})
</script>
<template>
	<div class="qi-search">
		<slot name="head"></slot>
		<el-form
			ref="formTep"
			:style="isOpen ? 'height: auto' + ';overflow:auto' : 'overflow:hidden'"
			class="form-inline"
			:class="['form-inline']"
			:inline="false"
			:label-position="labelPosition"
			:model="ruleForm"
		>
			<el-row class="search_box">
				<el-col v-for="formItem in formBaseData" :key="formItem.key" :span="formItem.span ? formItem.span : 8">
					<el-form-item v-if="!formItem.isHide" :prop="formItem.key" :label="formItem.label + ':'">
						<component :is="transType(formItem.type)" :rule-form="ruleForm" :form-item="formItem" :is-rest="isRest" @enter="clickSearch"> </component>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div class="btn_box">
			<div>
				<slot name='btnLeft'></slot>
				<div v-if="isShowSettings" @click="click_settings" class="flex-ctr cl-default cursor-pointer"><span class="iconfont iconshezhi mr5"></span>展示设置</div>
			</div>
			<div class="flex">
				<div class="btn btn_search" @click="clickSearch">搜索</div>
				<div class="btn btn_reset ml8" @click="clickResetForm">重置</div>
				<div v-if="isShowExport" class="btn btn_export ml8" @click="clickExport">导出</div>
				<slot name="btns"></slot>
				<div v-if="isShowOpen" class="btn-open" @click="clickOpenOrClose">
					<span v-show="!isOpen">展开</span>
					<span v-show="isOpen">收起</span>
					<span :class="['iconfont iconxia1', 'more', isOpen ? 'down' : '']"></span>
				</div>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup name="QiSearch">
import { reactive, ref, onMounted, nextTick } from 'vue';
import QiSelect from '../eleComponents/QiSelect.vue';
import QiInput from '../eleComponents/QiInput.vue';
import QiDatePicker from '../eleComponents/QiDatePicker.vue';
import utils from '@/utils/index';

const emit = defineEmits(['search', 'export', 'settings']);
const props = defineProps({
	labelPosition: {
		// 表单域标签的位置
		type: String,
		default: 'left',
	},
	labelWidth: {
		// 表单域标签的位置
		type: Number,
		default: 100,
	},
	formBaseData: {
		// 表单的配置项
		type: Array<any>,
		default(): any[] {
			return [];
		},
	},
	isShowOpen: {
		// 控制展开按钮
		type: Boolean,
		default: false,
	},
	isShowExport: {
		// 导出展示
		type: Boolean,
		default: false,
	},
	isShowSettings: {
		// 展示设置展示
		type: Boolean,
		default: false,
	},
});

const isRest = ref(false);
const formTep = ref();

const ruleForm: any = reactive({});
const formKeys: string[] = reactive([]);

const isOpen = ref(true);

onMounted(() => {
	init();
});

// 设置表单的数据和初始值
const init = () => {
	if (props.formBaseData.length > 0) {
		for (const iterator of props.formBaseData) {
			let initVal = iterator.initVal;
			if (utils.isUndefined(initVal)) {
				initVal = null;
			}
			formKeys.push(iterator.key);
			ruleForm[iterator.key] = initVal;
		}
	}
};

const transType = (type: number): any => {
	let componentName: any = null;
	switch (type) {
		case 0:
			componentName = QiInput;
			break;
		case 1:
			componentName = QiSelect;
			break;
		case 2:
			componentName = QiDatePicker;
			break;
	}
	return componentName;
};

const clickSearch = () => {
	emit('search', deleteInvalid(ruleForm));
};

const clickResetForm = () => {
	// 重置后恢复初始参数
	init();
	isRest.value = true;
	nextTick(() => {
		isRest.value = false;
		emit('search', deleteInvalid(ruleForm));
	});
};

const clickExport = () => {
	emit('export', deleteInvalid(ruleForm));
};

const click_settings = () => {
	emit('settings')
}

const clickOpenOrClose = () => {
	isOpen.value = !isOpen.value;
};

// 删除对象里面值为null和undefined和''的这些无效的字段
const deleteInvalid = (obj: any) => {
	Object.keys(obj).forEach((item) => {
		if ((!obj[item] && obj[item] != 0) || obj[item] === '') {
			delete obj[item];
		}
	});
	return obj;
};
</script>

<style lang="scss" scoped>
.qi-search {
	background: var(--no7-table-header-bg);
	padding: 16px 16px 0 16px;
	border-radius: 8px;
	margin-bottom: 16px;
}
/* elInput 下拉选择框
------------------------------- */
:deep(.el-input) {
	width: 240px;
	height: 40px;
}

/* elForm 表单
------------------------------- */
.form-inline {
	height: 58px;
	overflow: auto;
	transition: all 0.5s;
}
.el-form-item {
	align-items: center;
}

:deep(.el-date-editor) {
	width: 280px;
	height: 40px;
}

:deep(.el-date-editor) {
	max-width: 280px !important;
	height: 40px;
}

.f-show-all {
	height: auto;
}

.btn_box {
	display: flex;
	padding: 15px 0;
	border-top: 1px solid var(--no7-main-line);
	justify-content: space-between;
}
.btn {
	box-sizing: border-box;
	width: 60px;
	height: 32px;
	text-align: center;
	line-height: 32px;
	border-radius: 4px;
	font-size: var(--no7-theme-fz);
	cursor: pointer;

	&.btn_search {
		background: var(--no7-theme-color);
		color: #fff;
	}
	&.btn_export {
		background: #fff;
		color: var(--no7-theme-color);
		border: solid  1px var(--no7-theme-color);
	}

	&.btn_reset {
		border: 1px solid var(--no7-main-line);
		color: var(--no7-title-color);
	}
}

.btn-open {
	margin-left: 12px;
	line-height: 32px;
	color: var(--no7-theme-color);
	cursor: pointer;
	user-select: none;
}

.more {
	transition: all 0.5s;
	display: inline-block;
	font-size: 18px;
}
.down {
	transform: rotate(180deg);
}
</style>
