import { RouteRecordRaw } from 'vue-router';
const Layout = () => import('@/layout/index.vue');

const errorRouter: Array<RouteRecordRaw> = [
	{
		path: '/error',
		component: Layout,
		redirect: '/error/403',
		meta: {
			title: '错误页面',
		},
		children: [
			{
				path: '/error/403',
				name: '403',
				component: () => import('@/views/error/403.vue'),
				meta: {
					title: '403',
				},
			},
			{
				path: '/:path(.*)*',
				name: '404',
				component: () => import('@/views/error/404.vue'),
				meta: {
					title: '404',
				},
			},
			{
				path: '/error/500',
				name: '500',
				component: () => import('@/views/error/500.vue'),
				meta: {
					title: '500',
				},
			},
			{
				path: '/error/demo',
				name: 'demo',
				component: () => import('@/views/demo/index.vue'),
				meta: {
					title: '500',
				},
			},
		],
	},
];

export default errorRouter;
